@use '~/src/lib/mixins';

.Footer {
	border-top: 8px solid var(--color-offblack);
	background-color: var(--color-black);
	color: var(--color-white);

	nav {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		column-gap: var(--size-gap-2x);
		row-gap: var(--size-gap-quarter);
		align-items: flex-start;
		margin: var(--size-gap-3x) var(--size-gap-3x);
		font: var(--font-body-s);

		@include mixins.desktop {
			grid-template-columns: 30% repeat(5, 1fr);
			column-gap: var(--size-gap-3x);
			margin: var(--size-gap-3x) 0;
		}

		> section {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: flex-start;

			@include mixins.desktop {
				align-items: flex-start;
			}
		}

		.LogoContainer {
			display: flex;
			grid-column: 1 / span 3;
			justify-content: center;
			margin: var(--size-gap-2x);

			@include mixins.desktop {
				grid-column: auto;
				margin-top: var(--size-gap-2x);
				img {
					height: 80px;
				}
			}
		}

		section > div:first-child {
			margin-top: var(--size-gap-2x);
			padding: 4px 0;
			font: var(--font-title-l);
			font-size: 18px;
		}

		a {
			padding: 8px 0;
			color: var(--color-gray);
			font-size: 16px;
			@include mixins.desktop {
				padding: 4px;
			}
		}
		.Social {
			display: flex;
			grid-column: 1 / span 2;
			flex-direction: column;
			justify-content: center;

			@include mixins.desktop {
				flex-direction: row;
				justify-content: flex-start;
				gap: var(--size-gap-2x);
				margin-top: 0;
			}
			svg {
				width: 24px;
				height: 24px;
			}
		}
	}

	.LegalContainer {
		background-color: black;
		padding: var(--size-gap-2x) 0;
		color: var(--color-gray);
		font: var(--font-body-xs-r);
		> div > div {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: center;
			gap: var(--size-gap-2x);
			@include mixins.desktop {
				flex-direction: row;
			}
		}
		.Links {
			display: flex;
			flex-direction: row;
			gap: var(--size-gap-2x);
		}
	}
}
