@use '~/src/lib/mixins';

.IndexEvent {
	display: flex;
	flex-shrink: 1;
	flex-direction: column;
	gap: var(--size-gap-quarter);
	background-color: var(--color-black);
	color: var(--color-white);
	@include mixins.rounded;
	isolation: isolate; //Safari is trash
	min-width: 250px;
	max-width: 280px;
	a:hover {
		text-decoration: none;
		img {
			transform: scale(1.05);
		}
		// .Tickets {
		// 	text-decoration: underline;
		// }
	}

	.ImageContainer {
		border-bottom: 4px solid var(--color-brand-primary);
		overflow: hidden;
	}
	img {
		display: block;
		transform: scale(1);
		transition: transform 0.2s ease;
		aspect-ratio: 16/9;
		width: 100%;
		object-fit: cover;
	}
	.SoldOutOverlay {
		display: flex;
		position: absolute;
		top: 0;
		left: 0;
		justify-content: center;
		align-items: center;
		z-index: 1;
		background-color: rgba(255, 255, 255, 0.65);
		width: 100%;
		height: 100%;
		> div {
			background-color: var(--color-black);
			padding: var(--size-gap-quarter) var(--size-gap-3x);
			color: var(--color-white);
			font-size: var(--font-tiny);
			font-family: var(--font-highlight);
			text-align: center;
			text-transform: uppercase;
		}
	}
	.Text {
		padding: var(--size-gap-quarter) var(--size-gap-3x);
		.Title {
			margin-top: var(--size-gap-2x);
			width: 100%;
			max-width: max(140px, min(220px, 60vw));
			overflow: hidden;
			color: var(--color-white);
			font: var(--font-title-l);
			text-overflow: ellipsis;
			white-space: nowrap;
		}
		.Date {
			color: var(--color-white);
			font: var(--font-title-s);
		}
		.Tickets {
			display: block;
			margin: var(--size-gap-2x) 0;
			color: var(--color-brand-primary-20);
			font: var(--font-body-s-r);
			svg {
				margin-left: var(--size-gap-2x);
				color: var(--color-brand-primary);
			}

			&.SoldOut {
				color: var(--color-gray);
				text-decoration: line-through;
			}
		}
	}
}
