@use '~/src/lib/mixins';

.PageHeader {
	> div > div {
		margin: var(--size-gap-2x);
		background-color: var(--color-black);
		padding: var(--size-gap-2x) var(--size-gap-2x);
		color: var(--color-white);
		@include mixins.rounded;

		@include mixins.desktop {
			margin: var(--size-gap-2x) 0;
			padding: var(--size-gap-2x) var(--size-gap-3x);
		}

		h1 {
			color: var(--color-white);
			font: var(--font-headline-m);
			@include mixins.desktop {
				font: var(--font-headline-l);
			}
		}
		a {
			color: var(--color-white);
			&:hover {
				text-decoration: none;
			}
		}
	}

	.PageHeaderContainer {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
	}

	.PageHeaderCta {
		color: var(--color-brand-primary);
		font-size: 16px;
		text-align: right;

		a {
			color: var(--color-brand-primary-20);
			svg {
				padding-left: var(--size-gap-half);
				color: var(--color-brand-primary);
			}
		}
	}
}
