.cookie-consent {
  z-index: 100;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  color: #000;
  background-color: #ffffffd9;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: 100px;
  padding: 16px;
  transition: transform .3s ease-in .1s;
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  transform: translateY(100%);
}

.cookie-consent--dark {
  color: #fff;
  background-color: #000000d9;
}

.cookie-consent--dark .cookie-consent__container button {
  color: #fff;
  border: 1px solid #fff;
}

.cookie-consent--visible {
  transition-delay: 1s;
  transform: translateY(0);
}

.cookie-consent__container {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 16px;
  width: 100%;
  font-size: 12px;
  font-weight: 700;
  display: flex;
}

.cookie-consent__container svg {
  flex-shrink: 0;
  width: 25px;
  height: 30px;
}

.cookie-consent__container button {
  cursor: pointer;
  background-color: unset;
  color: #000;
  border: 1px solid #000;
  border-radius: 4px;
  width: 100%;
  max-width: 80px;
  padding: 8px;
  font-size: 12px;
  font-weight: 700;
}

:root {
  --color-brand-primary: #c3fa02;
  --color-brand-primary-10: #f6ffd9;
  --color-brand-primary-20: #ecfeae;
  --color-brand-primary-30: #dcfd67;
  --color-brand-secondary: #46928f;
  --color-brand-link: #46928f;
  --color-brand-primary-dark: #3e5001;
  --color-offblack: #333;
  --color-black: #1a1a1a;
  --color-white: #fff;
  --color-gray: #878787;
  --color-darkgray: #1c1e21;
  --color-lightgray: #ccc;
  --color-offwhite: #fafafa;
  --color-shadow: #00000040;
  --color-inner-shadow: #0000000d;
  --color-amazon-blue: #1a98ff;
  --color-youtube-red: red;
  --font-display-xl: 64px / 1.2 "black ops one", impact;
  --font-display-l: 56px / 1.2 "black ops one", impact;
  --font-display-m: 40px / 1.2 "black ops one", impact;
  --font-display-s: 24px / 1.2 "black ops one", impact;
  --font-display-xs: 18px / 1.2 "black ops one", impact;
  --font-headline-l: 300 40px / 1.2 "roboto flex", arial;
  --font-headline-m: 300 32px / 1.3 "roboto flex", arial;
  --font-headline-s: 300 24px / 1.3 "roboto flex", arial;
  --font-headline-xs: 300 16px / 1.3 "roboto flex", arial;
  --font-title-l: 600 24px / 1.33 "roboto flex", arial;
  --font-title-s: 600 18px / 1.33 "roboto flex", arial;
  --font-body-l-sb: 600 24px / 1.5 "roboto flex", arial;
  --font-body-l-r: 400 24px / 1.5 "roboto flex", arial;
  --font-body-m-sb: 600 18px / 1.5 "roboto flex", arial;
  --font-body-m-r: 400 18px / 1.5 "roboto flex", arial;
  --font-body-s-sb: 600 16px / 1.5 "roboto flex", arial;
  --font-body-s-r: 400 16px / 1.5 "roboto flex", arial;
  --font-body-xs-b: 700 12px / 1.5 "roboto flex", arial;
  --font-body-xs-r: 400 12px / 1.5 "roboto flex", arial;
  --font-number-l: 24px / 1.33 "black ops one", impact;
  --font-number-s: 18px / 1.33 "black ops one", impact;
  --font-eyebrow: 12px / 1.33 "black ops one", impact;
  --font-button: 600 14px / 16px "roboto flex", arial;
  --size-gap: var(--gap);
  --size-gap-quarter: calc(var(--gap) / 4);
  --size-gap-half: calc(var(--gap) / 2);
  --size-gap-2x: calc(var(--gap) * 2);
  --size-gap-3x: calc(var(--gap) * 3);
  --size-gap-4x: calc(var(--gap) * 4);
  --size-gap-6x: calc(var(--gap) * 6);
  --size-row-gap: var(--row-gap);
  --size-large-mobile-width: 640px;
  --size-tablet-width: 768px;
  --size-desktop-width: 990px;
  --size-content-width: calc(1366px - var(--size-gap) * 2);
}

@font-face {
  font-style: normal;
  font-weight: 400;
  src: url("https://fonts.gstatic.com/s/blackopsone/v20/qWcsB6-ypo7xBdr6Xshe96H3aDbbtxsis4IxXw.woff2") format("woff2");
  font-family: Black Ops One;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  src: url("https://fonts.gstatic.com/s/blackopsone/v20/qWcsB6-ypo7xBdr6Xshe96H3aDTbtxsis4IxXw.woff2") format("woff2");
  font-family: Black Ops One;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  src: url("https://fonts.gstatic.com/s/blackopsone/v20/qWcsB6-ypo7xBdr6Xshe96H3aDXbtxsis4IxXw.woff2") format("woff2");
  font-family: Black Ops One;
  font-display: swap;
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  src: url("https://fonts.gstatic.com/s/blackopsone/v20/qWcsB6-ypo7xBdr6Xshe96H3aDvbtxsis4I.woff2") format("woff2");
  font-family: Black Ops One;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 300;
  font-stretch: 130%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wCikXpYqmZsWI-__OGfttPZktqc2VdZ80KvCLZaPcSBZtOx2MifRuWR28sPJtUMbsFEK6cRrleUx9Xgbm3WLHa_F4Ep4Fm0PN19Ik5Dntczx0wZGzhPlL1YNMYKbv9_1IQXOw7AiUJVXRrV8cWW4O8LJCCXxhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 300;
  font-stretch: 130%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wCikXpYqmZsWI-__OGfttPZktqc2VdZ80KvCLZaPcSBZtOx2MifRuWR28sPJtUMbsFEK6cRrleUx9Xgbm3WLHa_F4Ep4Fm0PN19Ik5Dntczx0wZGzhPlL1YNMYKbv9_1IQXOw7AiUJVXRrV8cWW4O8LJCCVhhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 300;
  font-stretch: 130%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wCikXpYqmZsWI-__OGfttPZktqc2VdZ80KvCLZaPcSBZtOx2MifRuWR28sPJtUMbsFEK6cRrleUx9Xgbm3WLHa_F4Ep4Fm0PN19Ik5Dntczx0wZGzhPlL1YNMYKbv9_1IQXOw7AiUJVXRrV8cWW4O8LJCCURhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 300;
  font-stretch: 130%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wCikXpYqmZsWI-__OGfttPZktqc2VdZ80KvCLZaPcSBZtOx2MifRuWR28sPJtUMbsFEK6cRrleUx9Xgbm3WLHa_F4Ep4Fm0PN19Ik5Dntczx0wZGzhPlL1YNMYKbv9_1IQXOw7AiUJVXRrV8cWW4O8LJCCXRhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 300;
  font-stretch: 130%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wCikXpYqmZsWI-__OGfttPZktqc2VdZ80KvCLZaPcSBZtOx2MifRuWR28sPJtUMbsFEK6cRrleUx9Xgbm3WLHa_F4Ep4Fm0PN19Ik5Dntczx0wZGzhPlL1YNMYKbv9_1IQXOw7AiUJVXRrV8cWW4O8LJCCXBhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 300;
  font-stretch: 130%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wCikXpYqmZsWI-__OGfttPZktqc2VdZ80KvCLZaPcSBZtOx2MifRuWR28sPJtUMbsFEK6cRrleUx9Xgbm3WLHa_F4Ep4Fm0PN19Ik5Dntczx0wZGzhPlL1YNMYKbv9_1IQXOw7AiUJVXRrV8cWW4O8LJCCUhhK.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 130%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNeepOXO_NexZs0b5QrzlOHb8wCikXpYqmZsWI-__OGfttPZktqc2VdZ80KvCLZaPcSBZtOx2MifRuWR28sPJtUMbsFEK6cRrleUx9Xgbm3WLHa_F4Ep4Fm0PN19Ik5Dntczx0wZGzhPlL1YNMYKbv9_1IQXOw7AiUJVXRrV8cWW4O8LJCoXjC7-ShSaLshNP1c9-wmF9lp.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 130%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNeepOXO_NexZs0b5QrzlOHb8wCikXpYqmZsWI-__OGfttPZktqc2VdZ80KvCLZaPcSBZtOx2MifRuWR28sPJtUMbsFEK6cRrleUx9Xgbm3WLHa_F4Ep4Fm0PN19Ik5Dntczx0wZGzhPlL1YNMYKbv9_1IQXOw7AiUJVXRrV8cWW4O8LJCoXjC7-ShSaLshNP1c9-UmF9lp.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 130%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNeepOXO_NexZs0b5QrzlOHb8wCikXpYqmZsWI-__OGfttPZktqc2VdZ80KvCLZaPcSBZtOx2MifRuWR28sPJtUMbsFEK6cRrleUx9Xgbm3WLHa_F4Ep4Fm0PN19Ik5Dntczx0wZGzhPlL1YNMYKbv9_1IQXOw7AiUJVXRrV8cWW4O8LJCoXjC7-ShSaLshNP1c9-ImF9lp.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 130%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNeepOXO_NexZs0b5QrzlOHb8wCikXpYqmZsWI-__OGfttPZktqc2VdZ80KvCLZaPcSBZtOx2MifRuWR28sPJtUMbsFEK6cRrleUx9Xgbm3WLHa_F4Ep4Fm0PN19Ik5Dntczx0wZGzhPlL1YNMYKbv9_1IQXOw7AiUJVXRrV8cWW4O8LJCoXjC7-ShSaLshNP1c9-4mF9lp.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 130%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNeepOXO_NexZs0b5QrzlOHb8wCikXpYqmZsWI-__OGfttPZktqc2VdZ80KvCLZaPcSBZtOx2MifRuWR28sPJtUMbsFEK6cRrleUx9Xgbm3WLHa_F4Ep4Fm0PN19Ik5Dntczx0wZGzhPlL1YNMYKbv9_1IQXOw7AiUJVXRrV8cWW4O8LJCoXjC7-ShSaLshNP1c9-8mF9lp.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 130%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNeepOXO_NexZs0b5QrzlOHb8wCikXpYqmZsWI-__OGfttPZktqc2VdZ80KvCLZaPcSBZtOx2MifRuWR28sPJtUMbsFEK6cRrleUx9Xgbm3WLHa_F4Ep4Fm0PN19Ik5Dntczx0wZGzhPlL1YNMYKbv9_1IQXOw7AiUJVXRrV8cWW4O8LJCoXjC7-ShSaLshNP1c9-EmFw.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 130%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wCikXpYqmZsWI-__OGfttPZktqc2VdZ80KvCLZaPcSBZtOx2MifRuWR28sPJtUMbsFEK6cRrleUx9Xgbm3WLHa_F4Ep4Fm0PN19Ik5Dntczx0wZGzhPlL1YNMYKbv9_1IQXOw7AiUJVXRrV8cWW4O8LJCCXxhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 130%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wCikXpYqmZsWI-__OGfttPZktqc2VdZ80KvCLZaPcSBZtOx2MifRuWR28sPJtUMbsFEK6cRrleUx9Xgbm3WLHa_F4Ep4Fm0PN19Ik5Dntczx0wZGzhPlL1YNMYKbv9_1IQXOw7AiUJVXRrV8cWW4O8LJCCVhhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 130%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wCikXpYqmZsWI-__OGfttPZktqc2VdZ80KvCLZaPcSBZtOx2MifRuWR28sPJtUMbsFEK6cRrleUx9Xgbm3WLHa_F4Ep4Fm0PN19Ik5Dntczx0wZGzhPlL1YNMYKbv9_1IQXOw7AiUJVXRrV8cWW4O8LJCCURhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 130%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wCikXpYqmZsWI-__OGfttPZktqc2VdZ80KvCLZaPcSBZtOx2MifRuWR28sPJtUMbsFEK6cRrleUx9Xgbm3WLHa_F4Ep4Fm0PN19Ik5Dntczx0wZGzhPlL1YNMYKbv9_1IQXOw7AiUJVXRrV8cWW4O8LJCCXRhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 130%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wCikXpYqmZsWI-__OGfttPZktqc2VdZ80KvCLZaPcSBZtOx2MifRuWR28sPJtUMbsFEK6cRrleUx9Xgbm3WLHa_F4Ep4Fm0PN19Ik5Dntczx0wZGzhPlL1YNMYKbv9_1IQXOw7AiUJVXRrV8cWW4O8LJCCXBhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 130%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wCikXpYqmZsWI-__OGfttPZktqc2VdZ80KvCLZaPcSBZtOx2MifRuWR28sPJtUMbsFEK6cRrleUx9Xgbm3WLHa_F4Ep4Fm0PN19Ik5Dntczx0wZGzhPlL1YNMYKbv9_1IQXOw7AiUJVXRrV8cWW4O8LJCCUhhK.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 130%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wCikXpYqmZsWI-__OGfttPZktqc2VdZ80KvCLZaPcSBZtOx2MifRuWR28sPJtUMbsFEK6cRrleUx9Xgbm3WLHa_F4Ep4Fm0PN19Ik5Dntczx0wZGzhPlL1YNMYKbv9_1IQXOw7AiUJVXRrV8cWW4O8LJCCXxhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 130%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wCikXpYqmZsWI-__OGfttPZktqc2VdZ80KvCLZaPcSBZtOx2MifRuWR28sPJtUMbsFEK6cRrleUx9Xgbm3WLHa_F4Ep4Fm0PN19Ik5Dntczx0wZGzhPlL1YNMYKbv9_1IQXOw7AiUJVXRrV8cWW4O8LJCCVhhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 130%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wCikXpYqmZsWI-__OGfttPZktqc2VdZ80KvCLZaPcSBZtOx2MifRuWR28sPJtUMbsFEK6cRrleUx9Xgbm3WLHa_F4Ep4Fm0PN19Ik5Dntczx0wZGzhPlL1YNMYKbv9_1IQXOw7AiUJVXRrV8cWW4O8LJCCURhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 130%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wCikXpYqmZsWI-__OGfttPZktqc2VdZ80KvCLZaPcSBZtOx2MifRuWR28sPJtUMbsFEK6cRrleUx9Xgbm3WLHa_F4Ep4Fm0PN19Ik5Dntczx0wZGzhPlL1YNMYKbv9_1IQXOw7AiUJVXRrV8cWW4O8LJCCXRhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 130%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wCikXpYqmZsWI-__OGfttPZktqc2VdZ80KvCLZaPcSBZtOx2MifRuWR28sPJtUMbsFEK6cRrleUx9Xgbm3WLHa_F4Ep4Fm0PN19Ik5Dntczx0wZGzhPlL1YNMYKbv9_1IQXOw7AiUJVXRrV8cWW4O8LJCCXBhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 130%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wCikXpYqmZsWI-__OGfttPZktqc2VdZ80KvCLZaPcSBZtOx2MifRuWR28sPJtUMbsFEK6cRrleUx9Xgbm3WLHa_F4Ep4Fm0PN19Ik5Dntczx0wZGzhPlL1YNMYKbv9_1IQXOw7AiUJVXRrV8cWW4O8LJCCUhhK.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

:root {
  --header-background-color: black;
  --header-foreground-color: var(--color-white);
  --app-height: 100vh;
  --app-width: 100vw;
}

* {
  box-sizing: border-box;
  position: relative;
}

html {
  flex-direction: column;
  max-width: 100%;
  min-height: 100%;
  margin: 0;
  padding: 0;
  font-size: 16px;
  display: flex;
}

body {
  font: var(--font-body-m-r);
  background: #000;
  width: 100%;
  max-width: 100%;
  margin: 0;
  padding: 0;
}

p, li {
  line-height: 1.7;
}

input {
  font-family: var(--font-main);
}

body, #root {
  flex: 1;
  grid-template-rows: 1fr;
  grid-template-columns: [full-start] 1fr[full-end];
  display: grid;
}

.content {
  grid-column: full;
  grid-template-rows: 1fr;
  grid-template-columns: [full-start content-start] 1fr[content-end full-end];
  display: grid;
}

@media (width >= 760px) {
  .content {
    grid-template-columns: [full-start] 1fr [content-start] min(var(--app-width)  - var(--size-gap-2x) * 2, var(--size-content-width)) [content-end] 1fr [full-end];
  }
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

h1 {
  color: var(--color-brand-primary);
  font: var(--font-display-l);
}

h2 {
  color: var(--color-brand-primary);
  font: var(--font-display-m);
}

h3 {
  font: var(--font-display-s);
}

img {
  display: inline-block;
}

button {
  cursor: pointer;
  box-shadow: none;
  font-size: inherit;
  border: 0;
  outline: none;
}

[role="button"]:hover {
  cursor: pointer;
}

b {
  font-weight: 600;
}

:root {
  --gap: min(8px, 5vw);
  --row-gap: .55rem;
}

@media (width >= 990px) {
  :root {
    --gap: 8px;
  }
}

@media (width <= 990px) {
  .desktop-only {
    display: none !important;
  }
}

@media (width >= 990px) {
  .mobile-only {
    display: none !important;
  }
}

a {
  color: var(--color-brand-primary);
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

a.inherit {
  color: inherit;
  text-decoration: none;
}

a.inherit:hover {
  text-decoration: underline;
}

.padded {
  padding: var(--size-gap-2x);
}

:root {
  --color-brand-primary: #c3fa02;
  --color-brand-primary-10: #f6ffd9;
  --color-brand-primary-20: #ecfeae;
  --color-brand-primary-30: #dcfd67;
  --color-brand-secondary: #46928f;
  --color-brand-link: #46928f;
  --color-brand-primary-dark: #3e5001;
  --color-offblack: #333;
  --color-black: #1a1a1a;
  --color-white: #fff;
  --color-gray: #878787;
  --color-darkgray: #1c1e21;
  --color-lightgray: #ccc;
  --color-offwhite: #fafafa;
  --color-shadow: #00000040;
  --color-inner-shadow: #0000000d;
  --color-amazon-blue: #1a98ff;
  --color-youtube-red: red;
  --font-display-xl: 64px / 1.2 "black ops one", impact;
  --font-display-l: 56px / 1.2 "black ops one", impact;
  --font-display-m: 40px / 1.2 "black ops one", impact;
  --font-display-s: 24px / 1.2 "black ops one", impact;
  --font-display-xs: 18px / 1.2 "black ops one", impact;
  --font-headline-l: 300 40px / 1.2 "roboto flex", arial;
  --font-headline-m: 300 32px / 1.3 "roboto flex", arial;
  --font-headline-s: 300 24px / 1.3 "roboto flex", arial;
  --font-headline-xs: 300 16px / 1.3 "roboto flex", arial;
  --font-title-l: 600 24px / 1.33 "roboto flex", arial;
  --font-title-s: 600 18px / 1.33 "roboto flex", arial;
  --font-body-l-sb: 600 24px / 1.5 "roboto flex", arial;
  --font-body-l-r: 400 24px / 1.5 "roboto flex", arial;
  --font-body-m-sb: 600 18px / 1.5 "roboto flex", arial;
  --font-body-m-r: 400 18px / 1.5 "roboto flex", arial;
  --font-body-s-sb: 600 16px / 1.5 "roboto flex", arial;
  --font-body-s-r: 400 16px / 1.5 "roboto flex", arial;
  --font-body-xs-b: 700 12px / 1.5 "roboto flex", arial;
  --font-body-xs-r: 400 12px / 1.5 "roboto flex", arial;
  --font-number-l: 24px / 1.33 "black ops one", impact;
  --font-number-s: 18px / 1.33 "black ops one", impact;
  --font-eyebrow: 12px / 1.33 "black ops one", impact;
  --font-button: 600 14px / 16px "roboto flex", arial;
  --size-gap: var(--gap);
  --size-gap-quarter: calc(var(--gap) / 4);
  --size-gap-half: calc(var(--gap) / 2);
  --size-gap-2x: calc(var(--gap) * 2);
  --size-gap-3x: calc(var(--gap) * 3);
  --size-gap-4x: calc(var(--gap) * 4);
  --size-gap-6x: calc(var(--gap) * 6);
  --size-row-gap: var(--row-gap);
  --size-large-mobile-width: 640px;
  --size-tablet-width: 768px;
  --size-desktop-width: 990px;
  --size-content-width: calc(1366px - var(--size-gap) * 2);
}

.Suspended {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
}

.Error {
  justify-content: center;
  align-items: center;
  gap: var(--size-gap-2x);
  font-family: var(--font-highlight);
  flex-direction: row;
  display: flex;
}

:root {
  --color-brand-primary: #c3fa02;
  --color-brand-primary-10: #f6ffd9;
  --color-brand-primary-20: #ecfeae;
  --color-brand-primary-30: #dcfd67;
  --color-brand-secondary: #46928f;
  --color-brand-link: #46928f;
  --color-brand-primary-dark: #3e5001;
  --color-offblack: #333;
  --color-black: #1a1a1a;
  --color-white: #fff;
  --color-gray: #878787;
  --color-darkgray: #1c1e21;
  --color-lightgray: #ccc;
  --color-offwhite: #fafafa;
  --color-shadow: #00000040;
  --color-inner-shadow: #0000000d;
  --color-amazon-blue: #1a98ff;
  --color-youtube-red: red;
  --font-display-xl: 64px / 1.2 "black ops one", impact;
  --font-display-l: 56px / 1.2 "black ops one", impact;
  --font-display-m: 40px / 1.2 "black ops one", impact;
  --font-display-s: 24px / 1.2 "black ops one", impact;
  --font-display-xs: 18px / 1.2 "black ops one", impact;
  --font-headline-l: 300 40px / 1.2 "roboto flex", arial;
  --font-headline-m: 300 32px / 1.3 "roboto flex", arial;
  --font-headline-s: 300 24px / 1.3 "roboto flex", arial;
  --font-headline-xs: 300 16px / 1.3 "roboto flex", arial;
  --font-title-l: 600 24px / 1.33 "roboto flex", arial;
  --font-title-s: 600 18px / 1.33 "roboto flex", arial;
  --font-body-l-sb: 600 24px / 1.5 "roboto flex", arial;
  --font-body-l-r: 400 24px / 1.5 "roboto flex", arial;
  --font-body-m-sb: 600 18px / 1.5 "roboto flex", arial;
  --font-body-m-r: 400 18px / 1.5 "roboto flex", arial;
  --font-body-s-sb: 600 16px / 1.5 "roboto flex", arial;
  --font-body-s-r: 400 16px / 1.5 "roboto flex", arial;
  --font-body-xs-b: 700 12px / 1.5 "roboto flex", arial;
  --font-body-xs-r: 400 12px / 1.5 "roboto flex", arial;
  --font-number-l: 24px / 1.33 "black ops one", impact;
  --font-number-s: 18px / 1.33 "black ops one", impact;
  --font-eyebrow: 12px / 1.33 "black ops one", impact;
  --font-button: 600 14px / 16px "roboto flex", arial;
  --size-gap: var(--gap);
  --size-gap-quarter: calc(var(--gap) / 4);
  --size-gap-half: calc(var(--gap) / 2);
  --size-gap-2x: calc(var(--gap) * 2);
  --size-gap-3x: calc(var(--gap) * 3);
  --size-gap-4x: calc(var(--gap) * 4);
  --size-gap-6x: calc(var(--gap) * 6);
  --size-row-gap: var(--row-gap);
  --size-large-mobile-width: 640px;
  --size-tablet-width: 768px;
  --size-desktop-width: 990px;
  --size-content-width: calc(1366px - var(--size-gap) * 2);
}

.NotFound {
  margin-bottom: var(--size-gap-3x);
  color: var(--color-white);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.NotFound > h1 {
  margin: var(--size-gap-2x);
  font: var(--font-display-l);
}

.NotFound h2 {
  font: var(--font-display-m);
}

.ContentContainer > div {
  grid-column: content;
}

:root {
  --color-brand-primary: #c3fa02;
  --color-brand-primary-10: #f6ffd9;
  --color-brand-primary-20: #ecfeae;
  --color-brand-primary-30: #dcfd67;
  --color-brand-secondary: #46928f;
  --color-brand-link: #46928f;
  --color-brand-primary-dark: #3e5001;
  --color-offblack: #333;
  --color-black: #1a1a1a;
  --color-white: #fff;
  --color-gray: #878787;
  --color-darkgray: #1c1e21;
  --color-lightgray: #ccc;
  --color-offwhite: #fafafa;
  --color-shadow: #00000040;
  --color-inner-shadow: #0000000d;
  --color-amazon-blue: #1a98ff;
  --color-youtube-red: red;
  --font-display-xl: 64px / 1.2 "black ops one", impact;
  --font-display-l: 56px / 1.2 "black ops one", impact;
  --font-display-m: 40px / 1.2 "black ops one", impact;
  --font-display-s: 24px / 1.2 "black ops one", impact;
  --font-display-xs: 18px / 1.2 "black ops one", impact;
  --font-headline-l: 300 40px / 1.2 "roboto flex", arial;
  --font-headline-m: 300 32px / 1.3 "roboto flex", arial;
  --font-headline-s: 300 24px / 1.3 "roboto flex", arial;
  --font-headline-xs: 300 16px / 1.3 "roboto flex", arial;
  --font-title-l: 600 24px / 1.33 "roboto flex", arial;
  --font-title-s: 600 18px / 1.33 "roboto flex", arial;
  --font-body-l-sb: 600 24px / 1.5 "roboto flex", arial;
  --font-body-l-r: 400 24px / 1.5 "roboto flex", arial;
  --font-body-m-sb: 600 18px / 1.5 "roboto flex", arial;
  --font-body-m-r: 400 18px / 1.5 "roboto flex", arial;
  --font-body-s-sb: 600 16px / 1.5 "roboto flex", arial;
  --font-body-s-r: 400 16px / 1.5 "roboto flex", arial;
  --font-body-xs-b: 700 12px / 1.5 "roboto flex", arial;
  --font-body-xs-r: 400 12px / 1.5 "roboto flex", arial;
  --font-number-l: 24px / 1.33 "black ops one", impact;
  --font-number-s: 18px / 1.33 "black ops one", impact;
  --font-eyebrow: 12px / 1.33 "black ops one", impact;
  --font-button: 600 14px / 16px "roboto flex", arial;
  --size-gap: var(--gap);
  --size-gap-quarter: calc(var(--gap) / 4);
  --size-gap-half: calc(var(--gap) / 2);
  --size-gap-2x: calc(var(--gap) * 2);
  --size-gap-3x: calc(var(--gap) * 3);
  --size-gap-4x: calc(var(--gap) * 4);
  --size-gap-6x: calc(var(--gap) * 6);
  --size-row-gap: var(--row-gap);
  --size-large-mobile-width: 640px;
  --size-tablet-width: 768px;
  --size-desktop-width: 990px;
  --size-content-width: calc(1366px - var(--size-gap) * 2);
}

.Footer {
  border-top: 8px solid var(--color-offblack);
  background-color: var(--color-black);
  color: var(--color-white);
}

.Footer nav {
  column-gap: var(--size-gap-2x);
  row-gap: var(--size-gap-quarter);
  margin: var(--size-gap-3x) var(--size-gap-3x);
  font: var(--font-body-s);
  grid-template-columns: repeat(3, 1fr);
  align-items: flex-start;
  display: grid;
}

@media (width >= 990px) {
  .Footer nav {
    column-gap: var(--size-gap-3x);
    margin: var(--size-gap-3x) 0;
    grid-template-columns: 30% repeat(5, 1fr);
  }
}

.Footer nav > section {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

@media (width >= 990px) {
  .Footer nav > section {
    align-items: flex-start;
  }
}

.Footer nav .LogoContainer {
  margin: var(--size-gap-2x);
  grid-column: 1 / span 3;
  justify-content: center;
  display: flex;
}

@media (width >= 990px) {
  .Footer nav .LogoContainer {
    margin-top: var(--size-gap-2x);
    grid-column: auto;
  }

  .Footer nav .LogoContainer img {
    height: 80px;
  }
}

.Footer nav section > div:first-child {
  margin-top: var(--size-gap-2x);
  font: var(--font-title-l);
  padding: 4px 0;
  font-size: 18px;
}

.Footer nav a {
  color: var(--color-gray);
  padding: 8px 0;
  font-size: 16px;
}

@media (width >= 990px) {
  .Footer nav a {
    padding: 4px;
  }
}

.Footer nav .Social {
  flex-direction: column;
  grid-column: 1 / span 2;
  justify-content: center;
  display: flex;
}

@media (width >= 990px) {
  .Footer nav .Social {
    justify-content: flex-start;
    gap: var(--size-gap-2x);
    flex-direction: row;
    margin-top: 0;
  }
}

.Footer nav .Social svg {
  width: 24px;
  height: 24px;
}

.Footer .LegalContainer {
  padding: var(--size-gap-2x) 0;
  color: var(--color-gray);
  font: var(--font-body-xs-r);
  background-color: #000;
}

.Footer .LegalContainer > div > div {
  justify-content: space-between;
  align-items: center;
  gap: var(--size-gap-2x);
  flex-direction: column;
  display: flex;
}

@media (width >= 990px) {
  .Footer .LegalContainer > div > div {
    flex-direction: row;
  }
}

.Footer .LegalContainer .Links {
  gap: var(--size-gap-2x);
  flex-direction: row;
  display: flex;
}

.MenuIcon {
  padding: 4px;
}

@media (width >= 1024px) {
  .MenuIcon {
    display: none;
  }
}

.MenuIcon[aria-expanded="true"] {
  padding-left: 5px;
  padding-right: 5px;
}

.MobileMenu {
  z-index: -1;
  content-visibility: visible;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

@media (width >= 1024px) {
  .MobileMenu {
    display: none;
  }
}

.MobileMenu .Menu {
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  padding-bottom: var(--size-gap);
  width: var(--app-width);
  overscroll-behavior: contain;
  color: var(--theme-foreground-color);
  background-color: #000000d9;
  height: calc(100dvh - 100%);
  transition: transform .3s;
  display: block;
  position: absolute;
  top: 100%;
  left: 0;
  overflow-y: auto;
}

.MobileMenu .Menu[aria-hidden="true"] {
  transform: translateY(-100%);
}

.MobileMenu .Menu nav {
  gap: var(--size-gap-half);
  text-transform: none;
  flex-direction: column;
  display: flex;
}

:root {
  --color-brand-primary: #c3fa02;
  --color-brand-primary-10: #f6ffd9;
  --color-brand-primary-20: #ecfeae;
  --color-brand-primary-30: #dcfd67;
  --color-brand-secondary: #46928f;
  --color-brand-link: #46928f;
  --color-brand-primary-dark: #3e5001;
  --color-offblack: #333;
  --color-black: #1a1a1a;
  --color-white: #fff;
  --color-gray: #878787;
  --color-darkgray: #1c1e21;
  --color-lightgray: #ccc;
  --color-offwhite: #fafafa;
  --color-shadow: #00000040;
  --color-inner-shadow: #0000000d;
  --color-amazon-blue: #1a98ff;
  --color-youtube-red: red;
  --font-display-xl: 64px / 1.2 "black ops one", impact;
  --font-display-l: 56px / 1.2 "black ops one", impact;
  --font-display-m: 40px / 1.2 "black ops one", impact;
  --font-display-s: 24px / 1.2 "black ops one", impact;
  --font-display-xs: 18px / 1.2 "black ops one", impact;
  --font-headline-l: 300 40px / 1.2 "roboto flex", arial;
  --font-headline-m: 300 32px / 1.3 "roboto flex", arial;
  --font-headline-s: 300 24px / 1.3 "roboto flex", arial;
  --font-headline-xs: 300 16px / 1.3 "roboto flex", arial;
  --font-title-l: 600 24px / 1.33 "roboto flex", arial;
  --font-title-s: 600 18px / 1.33 "roboto flex", arial;
  --font-body-l-sb: 600 24px / 1.5 "roboto flex", arial;
  --font-body-l-r: 400 24px / 1.5 "roboto flex", arial;
  --font-body-m-sb: 600 18px / 1.5 "roboto flex", arial;
  --font-body-m-r: 400 18px / 1.5 "roboto flex", arial;
  --font-body-s-sb: 600 16px / 1.5 "roboto flex", arial;
  --font-body-s-r: 400 16px / 1.5 "roboto flex", arial;
  --font-body-xs-b: 700 12px / 1.5 "roboto flex", arial;
  --font-body-xs-r: 400 12px / 1.5 "roboto flex", arial;
  --font-number-l: 24px / 1.33 "black ops one", impact;
  --font-number-s: 18px / 1.33 "black ops one", impact;
  --font-eyebrow: 12px / 1.33 "black ops one", impact;
  --font-button: 600 14px / 16px "roboto flex", arial;
  --size-gap: var(--gap);
  --size-gap-quarter: calc(var(--gap) / 4);
  --size-gap-half: calc(var(--gap) / 2);
  --size-gap-2x: calc(var(--gap) * 2);
  --size-gap-3x: calc(var(--gap) * 3);
  --size-gap-4x: calc(var(--gap) * 4);
  --size-gap-6x: calc(var(--gap) * 6);
  --size-row-gap: var(--row-gap);
  --size-large-mobile-width: 640px;
  --size-tablet-width: 768px;
  --size-desktop-width: 990px;
  --size-content-width: calc(1366px - var(--size-gap) * 2);
}

.Header {
  z-index: 100;
  border-bottom: 1px solid var(--color-offblack);
  color: var(--header-foreground-color);
  position: sticky;
  top: 0;
}

.Header .Background {
  z-index: 0;
  background-color: var(--header-background-color);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.Header .HeaderContainer {
  padding: var(--size-gap-quarter) var(--size-gap-2x);
  text-transform: uppercase;
  flex-direction: row;
  grid-template-columns: auto 1fr auto;
  justify-content: space-between;
  align-items: center;
  gap: 32px;
  display: grid;
}

@media (width <= 420px) {
  .Header .HeaderContainer {
    gap: 16px;
  }
}

@media (width >= 990px) {
  .Header .HeaderContainer {
    padding: var(--size-gap-quarter);
  }
}

.Header .HeaderContainer .Logo {
  padding: var(--size-gap-2x) 0;
}

@media (width >= 990px) {
  .Header .HeaderContainer .Logo {
    margin-right: var(--size-gap-2x);
  }
}

.Header .HeaderContainer .Logo img {
  width: 60px;
  height: 23px;
  display: block;
}

.Header .HeaderContainer .LinksContainer {
  font: var(--font-button);
  text-transform: uppercase;
  white-space: nowrap;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 32px;
  padding: 16px 0;
  display: flex;
  top: 2px;
}

@media (width <= 420px) {
  .Header .HeaderContainer .LinksContainer {
    gap: 16px;
  }
}

.Header .HeaderContainer .LinksContainer--Desktop {
  justify-content: flex-start;
  margin-left: 24px;
  display: none;
}

@media (width >= 990px) {
  .Header .HeaderContainer .LinksContainer--Desktop {
    display: unset;
  }
}

.Header .HeaderContainer .LinksContainer a {
  color: var(--color-gray);
  font: var(--font-button);
  top: 0;
}

.Header .HeaderContainer .LinksContainer .Tickets {
  color: var(--color-brand-primary);
  font: var(--font-eyebrow);
}

.Header .HeaderContainer .LinksContainer .Tickets svg {
  margin-right: var(--size-gap);
}

.Header .HeaderContainer .LinksContainer .Shop {
  color: var(--color-brand-primary);
  font: var(--font-eyebrow);
}

.Header .HeaderContainer .LinksContainer .Shop svg {
  margin-right: var(--size-gap);
}

.Header .HeaderContainer .LinksContainer .DesktopLinks {
  display: none;
}

@media (width >= 990px) {
  .Header .HeaderContainer .LinksContainer .DesktopLinks {
    gap: var(--size-gap-4x);
    flex-direction: row;
    display: flex;
  }
}

.Header .HeaderContainer .LinksContainer .DesktopLinks a:after {
  top: 0;
  right: calc(-1 * var(--size-gap-2x)  - 1px);
  background-color: var(--color-brand-primary);
  content: "";
  width: 1px;
  height: 100%;
  display: block;
  position: absolute;
}

.Header .HeaderContainer .LinksContainer .DesktopLinks a:last-of-type:after {
  display: none;
}

.Header .MenuIcon {
  color: var(--color-brand-primary);
}

.Header nav a {
  margin: 0 var(--size-gap-3x);
  border-bottom: 1px solid var(--color-brand-primary);
  padding: var(--size-gap-3x) 0;
  color: var(--color-gray);
  text-transform: uppercase;
}

.Layout {
  grid-template-rows: auto 1fr auto;
  display: grid;
  grid-column: full !important;
}



.Button {
  border: 1px solid var(--color-brand-primary);
  background-color: var(--color-brand-primary);
  padding: var(--size-gap-2x);
  color: var(--color-black);
  font: var(--font-button);
  text-align: center;
  text-transform: uppercase;
  border-radius: 1000px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.Button.Medium {
  height: 40px;
  padding: 0 40px;
}

.Button.Small {
  height: 40px;
  padding: 0 32px;
}

.Button.Inverted {
  border: 1px solid var(--color-brand-primary);
  color: var(--color-brand-primary);
  background: #000;
}

.Button:hover {
  text-decoration: none;
}

.Button:hover:after {
  opacity: 1;
}

.Button:after {
  opacity: 0;
  border-radius: inherit;
  background-image: linear-gradient(140deg, var(--color-brand-primary), var(--color-brand-primary-10));
  content: "";
  transition: all .2s;
  position: absolute;
  inset: 0;
}

.Button.Inverted:after {
  background-image: linear-gradient(140deg, black, var(--color-brand-primary-dark));
}

.Button > div {
  z-index: 1;
}

.Eyebrow {
  text-align: center;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.Eyebrow.FullWidth {
  align-items: stretch;
}

.Eyebrow .EyebrowText {
  background-color: var(--color-brand-primary-20);
  padding: 0 var(--size-gap-2x);
  color: var(--color-black);
  font: var(--font-eyebrow);
  text-transform: uppercase;
  line-height: 2 !important;
}

.Picture {
  width: 100%;
}

.Picture img {
  width: 100%;
  display: block;
}

.Picture .PictureFull {
  z-index: 1;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

:root {
  --color-brand-primary: #c3fa02;
  --color-brand-primary-10: #f6ffd9;
  --color-brand-primary-20: #ecfeae;
  --color-brand-primary-30: #dcfd67;
  --color-brand-secondary: #46928f;
  --color-brand-link: #46928f;
  --color-brand-primary-dark: #3e5001;
  --color-offblack: #333;
  --color-black: #1a1a1a;
  --color-white: #fff;
  --color-gray: #878787;
  --color-darkgray: #1c1e21;
  --color-lightgray: #ccc;
  --color-offwhite: #fafafa;
  --color-shadow: #00000040;
  --color-inner-shadow: #0000000d;
  --color-amazon-blue: #1a98ff;
  --color-youtube-red: red;
  --font-display-xl: 64px / 1.2 "black ops one", impact;
  --font-display-l: 56px / 1.2 "black ops one", impact;
  --font-display-m: 40px / 1.2 "black ops one", impact;
  --font-display-s: 24px / 1.2 "black ops one", impact;
  --font-display-xs: 18px / 1.2 "black ops one", impact;
  --font-headline-l: 300 40px / 1.2 "roboto flex", arial;
  --font-headline-m: 300 32px / 1.3 "roboto flex", arial;
  --font-headline-s: 300 24px / 1.3 "roboto flex", arial;
  --font-headline-xs: 300 16px / 1.3 "roboto flex", arial;
  --font-title-l: 600 24px / 1.33 "roboto flex", arial;
  --font-title-s: 600 18px / 1.33 "roboto flex", arial;
  --font-body-l-sb: 600 24px / 1.5 "roboto flex", arial;
  --font-body-l-r: 400 24px / 1.5 "roboto flex", arial;
  --font-body-m-sb: 600 18px / 1.5 "roboto flex", arial;
  --font-body-m-r: 400 18px / 1.5 "roboto flex", arial;
  --font-body-s-sb: 600 16px / 1.5 "roboto flex", arial;
  --font-body-s-r: 400 16px / 1.5 "roboto flex", arial;
  --font-body-xs-b: 700 12px / 1.5 "roboto flex", arial;
  --font-body-xs-r: 400 12px / 1.5 "roboto flex", arial;
  --font-number-l: 24px / 1.33 "black ops one", impact;
  --font-number-s: 18px / 1.33 "black ops one", impact;
  --font-eyebrow: 12px / 1.33 "black ops one", impact;
  --font-button: 600 14px / 16px "roboto flex", arial;
  --size-gap: var(--gap);
  --size-gap-quarter: calc(var(--gap) / 4);
  --size-gap-half: calc(var(--gap) / 2);
  --size-gap-2x: calc(var(--gap) * 2);
  --size-gap-3x: calc(var(--gap) * 3);
  --size-gap-4x: calc(var(--gap) * 4);
  --size-gap-6x: calc(var(--gap) * 6);
  --size-row-gap: var(--row-gap);
  --size-large-mobile-width: 640px;
  --size-tablet-width: 768px;
  --size-desktop-width: 990px;
  --size-content-width: calc(1366px - var(--size-gap) * 2);
}

.IndexEvent {
  gap: var(--size-gap-quarter);
  background-color: var(--color-black);
  color: var(--color-white);
  isolation: isolate;
  border-radius: 16px;
  flex-direction: column;
  flex-shrink: 1;
  min-width: 250px;
  max-width: 280px;
  display: flex;
  overflow: hidden;
}

.IndexEvent a:hover {
  text-decoration: none;
}

.IndexEvent a:hover img {
  transform: scale(1.05);
}

.IndexEvent .ImageContainer {
  border-bottom: 4px solid var(--color-brand-primary);
  overflow: hidden;
}

.IndexEvent img {
  aspect-ratio: 16 / 9;
  object-fit: cover;
  width: 100%;
  transition: transform .2s;
  display: block;
  transform: scale(1);
}

.IndexEvent .SoldOutOverlay {
  z-index: 1;
  background-color: #ffffffa6;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.IndexEvent .SoldOutOverlay > div {
  background-color: var(--color-black);
  padding: var(--size-gap-quarter) var(--size-gap-3x);
  color: var(--color-white);
  font-size: var(--font-tiny);
  font-family: var(--font-highlight);
  text-align: center;
  text-transform: uppercase;
}

.IndexEvent .Text {
  padding: var(--size-gap-quarter) var(--size-gap-3x);
}

.IndexEvent .Text .Title {
  margin-top: var(--size-gap-2x);
  color: var(--color-white);
  font: var(--font-title-l);
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  max-width: max(140px, min(220px, 60vw));
  overflow: hidden;
}

.IndexEvent .Text .Date {
  color: var(--color-white);
  font: var(--font-title-s);
}

.IndexEvent .Text .Tickets {
  margin: var(--size-gap-2x) 0;
  color: var(--color-brand-primary-20);
  font: var(--font-body-s-r);
  display: block;
}

.IndexEvent .Text .Tickets svg {
  margin-left: var(--size-gap-2x);
  color: var(--color-brand-primary);
}

.IndexEvent .Text .Tickets.SoldOut {
  color: var(--color-gray);
  text-decoration: line-through;
}

.TBD {
  margin: var(--size-gap-2x) 0;
  color: var(--color-brand-primary-10);
  font: var(--font-display-l);
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.IndexGame {
  gap: var(--size-gap);
  padding-bottom: var(--size-gap-2x);
  flex-direction: column;
  display: flex;
}

.IndexGame.Scheduled .Points {
  display: none;
  color: var(--color-gray) !important;
}

.IndexGame .Purpose {
  background-color: var(--color-brand-primary-20);
  padding: var(--size-gap-half);
  color: var(--color-black);
  font: var(--font-eyebrow);
  text-align: center;
  text-transform: uppercase;
}

.IndexGame .GameTeam {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.IndexGame .GameTeam:first-of-type {
  border-bottom: 1px solid var(--color-offblack);
  padding-bottom: var(--size-gap);
}

.IndexGame .GameTeam .LogoName {
  align-items: center;
  gap: var(--size-gap);
  flex-direction: row;
  display: flex;
}

.IndexGame .GameTeam .Logo {
  border: 1px solid var(--color-gray);
  aspect-ratio: 1;
  border-radius: 50%;
  width: 32px;
}

.IndexGame .GameTeam .Logo.Placeholder {
  padding: 6px;
}

.IndexGame .GameTeam .Name {
  font: var(--font-body-s-sb);
}

.IndexGame .GameTeam .Name.Loser {
  color: var(--color-gray);
}

.IndexGame .GameTeam .TBD {
  margin: 0 0 calc(-1 * var(--size-gap-half));
  line-height: 32px;
  font: var(--font-display-xs);
  height: 32px;
}

.IndexGame .GameTeam .Points {
  color: var(--color-brand-primary-20);
  font: var(--font-number-l);
}

.IndexGame .GameTeam .Points.Leader {
  color: var(--color-brand-primary);
}

.IndexGame .GameTeam .Points.Loser {
  color: var(--color-gray);
}

:root {
  --color-brand-primary: #c3fa02;
  --color-brand-primary-10: #f6ffd9;
  --color-brand-primary-20: #ecfeae;
  --color-brand-primary-30: #dcfd67;
  --color-brand-secondary: #46928f;
  --color-brand-link: #46928f;
  --color-brand-primary-dark: #3e5001;
  --color-offblack: #333;
  --color-black: #1a1a1a;
  --color-white: #fff;
  --color-gray: #878787;
  --color-darkgray: #1c1e21;
  --color-lightgray: #ccc;
  --color-offwhite: #fafafa;
  --color-shadow: #00000040;
  --color-inner-shadow: #0000000d;
  --color-amazon-blue: #1a98ff;
  --color-youtube-red: red;
  --font-display-xl: 64px / 1.2 "black ops one", impact;
  --font-display-l: 56px / 1.2 "black ops one", impact;
  --font-display-m: 40px / 1.2 "black ops one", impact;
  --font-display-s: 24px / 1.2 "black ops one", impact;
  --font-display-xs: 18px / 1.2 "black ops one", impact;
  --font-headline-l: 300 40px / 1.2 "roboto flex", arial;
  --font-headline-m: 300 32px / 1.3 "roboto flex", arial;
  --font-headline-s: 300 24px / 1.3 "roboto flex", arial;
  --font-headline-xs: 300 16px / 1.3 "roboto flex", arial;
  --font-title-l: 600 24px / 1.33 "roboto flex", arial;
  --font-title-s: 600 18px / 1.33 "roboto flex", arial;
  --font-body-l-sb: 600 24px / 1.5 "roboto flex", arial;
  --font-body-l-r: 400 24px / 1.5 "roboto flex", arial;
  --font-body-m-sb: 600 18px / 1.5 "roboto flex", arial;
  --font-body-m-r: 400 18px / 1.5 "roboto flex", arial;
  --font-body-s-sb: 600 16px / 1.5 "roboto flex", arial;
  --font-body-s-r: 400 16px / 1.5 "roboto flex", arial;
  --font-body-xs-b: 700 12px / 1.5 "roboto flex", arial;
  --font-body-xs-r: 400 12px / 1.5 "roboto flex", arial;
  --font-number-l: 24px / 1.33 "black ops one", impact;
  --font-number-s: 18px / 1.33 "black ops one", impact;
  --font-eyebrow: 12px / 1.33 "black ops one", impact;
  --font-button: 600 14px / 16px "roboto flex", arial;
  --size-gap: var(--gap);
  --size-gap-quarter: calc(var(--gap) / 4);
  --size-gap-half: calc(var(--gap) / 2);
  --size-gap-2x: calc(var(--gap) * 2);
  --size-gap-3x: calc(var(--gap) * 3);
  --size-gap-4x: calc(var(--gap) * 4);
  --size-gap-6x: calc(var(--gap) * 6);
  --size-row-gap: var(--row-gap);
  --size-large-mobile-width: 640px;
  --size-tablet-width: 768px;
  --size-desktop-width: 990px;
  --size-content-width: calc(1366px - var(--size-gap) * 2);
}

.IndexGames {
  border: 1px solid var(--color-brand-primary);
  gap: var(--size-gap-2x);
  padding: var(--size-gap-2x);
  color: var(--color-white);
  border-radius: 16px;
  width: 100%;
  margin: 0 16px;
  display: grid;
  overflow: hidden;
}

@media (width >= 990px) {
  .IndexGames {
    max-width: 810px;
    margin: 0;
  }
}

.IndexGames h2 {
  color: var(--color-white);
  font: var(--font-headline-s);
}

.IndexGames .Button {
  align-self: center;
  width: 100%;
  max-width: 240px;
}

.IndexGames .More:before {
  top: calc(-1 * var(--size-gap-2x));
  background-color: var(--color-brand-primary);
  content: "";
  width: 100%;
  height: 1px;
  display: block;
  position: absolute;
  left: 0;
}

.IndexGames .Games {
  row-gap: var(--size-gap-2x);
  grid-template-columns: 1fr;
  display: grid;
}

@media (width >= 990px) {
  .IndexGames .Games {
    column-gap: var(--size-gap-2x);
  }

  .IndexGames .Games.MultiColumn {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .IndexGames .Games.MultiColumn .Game:nth-last-child(-n+3) {
    border-bottom-color: #0000;
  }
}

.Tabs {
  position: relative;
}

.Tabs .TabsLinks {
  top: var(--size-gap-2x);
  margin-bottom: calc(-1 * var(--size-gap-2x));
  padding: 0;
  padding-right: var(--size-gap-4x);
  padding-bottom: var(--size-gap-2x);
  padding-left: var(--size-gap-half);
  max-width: var(--app-width);
  font: var(--font-headline-xs);
  display: flex;
  overflow-x: auto;
}

.Tabs .TabsLinks > a {
  padding: var(--size-gap-2x) var(--size-gap) var(--size-gap);
  white-space: nowrap;
  border-bottom: 1px solid #0000;
  display: block;
}

.Tabs .TabsLinks > a:hover {
  text-decoration: none;
}

.Tabs .TabsLinks > a.current {
  color: var(--color-brand-primary-20);
}

.Tabs:before {
  z-index: 1;
  background: linear-gradient(90deg, var(--color-black), transparent);
  width: var(--size-gap-2x);
  pointer-events: none;
  content: "";
  height: 100%;
  position: absolute;
  bottom: 4px;
  left: 0;
}

.Tabs:after {
  background: linear-gradient(90deg, transparent, var(--color-black) var(--size-gap-4x));
  width: var(--size-gap-6x);
  pointer-events: none;
  content: "";
  height: 100%;
  position: absolute;
  bottom: 4px;
  right: 0;
}

:root {
  --color-brand-primary: #c3fa02;
  --color-brand-primary-10: #f6ffd9;
  --color-brand-primary-20: #ecfeae;
  --color-brand-primary-30: #dcfd67;
  --color-brand-secondary: #46928f;
  --color-brand-link: #46928f;
  --color-brand-primary-dark: #3e5001;
  --color-offblack: #333;
  --color-black: #1a1a1a;
  --color-white: #fff;
  --color-gray: #878787;
  --color-darkgray: #1c1e21;
  --color-lightgray: #ccc;
  --color-offwhite: #fafafa;
  --color-shadow: #00000040;
  --color-inner-shadow: #0000000d;
  --color-amazon-blue: #1a98ff;
  --color-youtube-red: red;
  --font-display-xl: 64px / 1.2 "black ops one", impact;
  --font-display-l: 56px / 1.2 "black ops one", impact;
  --font-display-m: 40px / 1.2 "black ops one", impact;
  --font-display-s: 24px / 1.2 "black ops one", impact;
  --font-display-xs: 18px / 1.2 "black ops one", impact;
  --font-headline-l: 300 40px / 1.2 "roboto flex", arial;
  --font-headline-m: 300 32px / 1.3 "roboto flex", arial;
  --font-headline-s: 300 24px / 1.3 "roboto flex", arial;
  --font-headline-xs: 300 16px / 1.3 "roboto flex", arial;
  --font-title-l: 600 24px / 1.33 "roboto flex", arial;
  --font-title-s: 600 18px / 1.33 "roboto flex", arial;
  --font-body-l-sb: 600 24px / 1.5 "roboto flex", arial;
  --font-body-l-r: 400 24px / 1.5 "roboto flex", arial;
  --font-body-m-sb: 600 18px / 1.5 "roboto flex", arial;
  --font-body-m-r: 400 18px / 1.5 "roboto flex", arial;
  --font-body-s-sb: 600 16px / 1.5 "roboto flex", arial;
  --font-body-s-r: 400 16px / 1.5 "roboto flex", arial;
  --font-body-xs-b: 700 12px / 1.5 "roboto flex", arial;
  --font-body-xs-r: 400 12px / 1.5 "roboto flex", arial;
  --font-number-l: 24px / 1.33 "black ops one", impact;
  --font-number-s: 18px / 1.33 "black ops one", impact;
  --font-eyebrow: 12px / 1.33 "black ops one", impact;
  --font-button: 600 14px / 16px "roboto flex", arial;
  --size-gap: var(--gap);
  --size-gap-quarter: calc(var(--gap) / 4);
  --size-gap-half: calc(var(--gap) / 2);
  --size-gap-2x: calc(var(--gap) * 2);
  --size-gap-3x: calc(var(--gap) * 3);
  --size-gap-4x: calc(var(--gap) * 4);
  --size-gap-6x: calc(var(--gap) * 6);
  --size-row-gap: var(--row-gap);
  --size-large-mobile-width: 640px;
  --size-tablet-width: 768px;
  --size-desktop-width: 990px;
  --size-content-width: calc(1366px - var(--size-gap) * 2);
}

.PageHeader > div > div {
  margin: var(--size-gap-2x);
  background-color: var(--color-black);
  padding: var(--size-gap-2x) var(--size-gap-2x);
  color: var(--color-white);
  border-radius: 16px;
  overflow: hidden;
}

@media (width >= 990px) {
  .PageHeader > div > div {
    margin: var(--size-gap-2x) 0;
    padding: var(--size-gap-2x) var(--size-gap-3x);
  }
}

.PageHeader > div > div h1 {
  color: var(--color-white);
  font: var(--font-headline-m);
}

@media (width >= 990px) {
  .PageHeader > div > div h1 {
    font: var(--font-headline-l);
  }
}

.PageHeader > div > div a {
  color: var(--color-white);
}

.PageHeader > div > div a:hover {
  text-decoration: none;
}

.PageHeader .PageHeaderContainer {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.PageHeader .PageHeaderCta {
  color: var(--color-brand-primary);
  text-align: right;
  font-size: 16px;
}

.PageHeader .PageHeaderCta a {
  color: var(--color-brand-primary-20);
}

.PageHeader .PageHeaderCta a svg {
  padding-left: var(--size-gap-half);
  color: var(--color-brand-primary);
}

:root {
  --color-brand-primary: #c3fa02;
  --color-brand-primary-10: #f6ffd9;
  --color-brand-primary-20: #ecfeae;
  --color-brand-primary-30: #dcfd67;
  --color-brand-secondary: #46928f;
  --color-brand-link: #46928f;
  --color-brand-primary-dark: #3e5001;
  --color-offblack: #333;
  --color-black: #1a1a1a;
  --color-white: #fff;
  --color-gray: #878787;
  --color-darkgray: #1c1e21;
  --color-lightgray: #ccc;
  --color-offwhite: #fafafa;
  --color-shadow: #00000040;
  --color-inner-shadow: #0000000d;
  --color-amazon-blue: #1a98ff;
  --color-youtube-red: red;
  --font-display-xl: 64px / 1.2 "black ops one", impact;
  --font-display-l: 56px / 1.2 "black ops one", impact;
  --font-display-m: 40px / 1.2 "black ops one", impact;
  --font-display-s: 24px / 1.2 "black ops one", impact;
  --font-display-xs: 18px / 1.2 "black ops one", impact;
  --font-headline-l: 300 40px / 1.2 "roboto flex", arial;
  --font-headline-m: 300 32px / 1.3 "roboto flex", arial;
  --font-headline-s: 300 24px / 1.3 "roboto flex", arial;
  --font-headline-xs: 300 16px / 1.3 "roboto flex", arial;
  --font-title-l: 600 24px / 1.33 "roboto flex", arial;
  --font-title-s: 600 18px / 1.33 "roboto flex", arial;
  --font-body-l-sb: 600 24px / 1.5 "roboto flex", arial;
  --font-body-l-r: 400 24px / 1.5 "roboto flex", arial;
  --font-body-m-sb: 600 18px / 1.5 "roboto flex", arial;
  --font-body-m-r: 400 18px / 1.5 "roboto flex", arial;
  --font-body-s-sb: 600 16px / 1.5 "roboto flex", arial;
  --font-body-s-r: 400 16px / 1.5 "roboto flex", arial;
  --font-body-xs-b: 700 12px / 1.5 "roboto flex", arial;
  --font-body-xs-r: 400 12px / 1.5 "roboto flex", arial;
  --font-number-l: 24px / 1.33 "black ops one", impact;
  --font-number-s: 18px / 1.33 "black ops one", impact;
  --font-eyebrow: 12px / 1.33 "black ops one", impact;
  --font-button: 600 14px / 16px "roboto flex", arial;
  --size-gap: var(--gap);
  --size-gap-quarter: calc(var(--gap) / 4);
  --size-gap-half: calc(var(--gap) / 2);
  --size-gap-2x: calc(var(--gap) * 2);
  --size-gap-3x: calc(var(--gap) * 3);
  --size-gap-4x: calc(var(--gap) * 4);
  --size-gap-6x: calc(var(--gap) * 6);
  --size-row-gap: var(--row-gap);
  --size-large-mobile-width: 640px;
  --size-tablet-width: 768px;
  --size-desktop-width: 990px;
  --size-content-width: calc(1366px - var(--size-gap) * 2);
}

.Table table {
  border-spacing: 0;
  table-layout: auto;
  border-width: 0;
  width: 100%;
}

.Table thead tr th {
  padding: var(--size-gap-2x);
  color: var(--color-white);
  font: var(--font-body-s-sb);
  text-align: center;
  white-space: nowrap;
  background-color: #000;
}

.Table thead tr th:first-child {
  z-index: 1;
  padding: var(--size-gap-2x);
  text-align: left;
  min-width: 160px;
  position: sticky;
  left: 0;
}

@media (width >= 990px) {
  .Table thead tr th:first-child {
    min-width: 160px;
  }
}

.Table thead tr th:nth-child(n+2) {
  min-width: 60px;
}

.Table thead tr th.current {
  background-color: var(--color-brand-secondary);
}

.Table tbody th {
  z-index: 1;
  background-color: var(--color-offblack);
  padding: var(--size-gap-2x);
  text-align: left;
  position: sticky;
  left: 0;
}

.Table tbody th, .Table tbody td {
  color: var(--color-white);
  font: var(--font-body-s-sb);
}

.Table tbody td {
  background-color: var(--color-black);
  text-align: center;
  min-width: 40px;
}

.TeamsLinks {
  align-items: center;
  gap: var(--size-gap-2x);
  display: flex;
}

.TeamsLinks a {
  color: var(--color-brand-primary-20);
}

.TeamsLogo {
  border-radius: 50%;
  width: 56px;
  text-decoration: none !important;
}

.TeamsLogo img, .TeamsLogo .Placeholder {
  aspect-ratio: 1;
  border-radius: 50%;
  width: 100%;
  display: block;
}

.TeamsLogo .Placeholder {
  background-color: var(--color-white);
  color: var(--color-black);
  font: var(--font-display-m);
  text-align: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding-top: 2px;
  display: flex;
}

/*# sourceMappingURL=app.css.map */
