.Eyebrow {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	text-align: center;
	&.FullWidth {
		align-items: stretch;
	}
	.EyebrowText {
		background-color: var(--color-brand-primary-20);
		padding: 0 var(--size-gap-2x);
		color: var(--color-black);
		line-height: 2 !important;
		font: var(--font-eyebrow);
		text-transform: uppercase;
	}
}
