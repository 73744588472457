@use '~/src/lib/_root';
@use '~/src/lib/mixins';

//Inline from https://fonts.googleapis.com/css2?family=Black+Ops+One&family=Roboto+Flex:opsz,wght@8..144,300;8..144,400;8..144,600;8..144,700&display=swap
/* cyrillic-ext */
@font-face {
	font-style: normal;
	font-weight: 400;
	src: url(https://fonts.gstatic.com/s/blackopsone/v20/qWcsB6-ypo7xBdr6Xshe96H3aDbbtxsis4IxXw.woff2) format('woff2');
	font-family: 'Black Ops One';
	font-display: swap;
	unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* vietnamese */
@font-face {
	font-style: normal;
	font-weight: 400;
	src: url(https://fonts.gstatic.com/s/blackopsone/v20/qWcsB6-ypo7xBdr6Xshe96H3aDTbtxsis4IxXw.woff2) format('woff2');
	font-family: 'Black Ops One';
	font-display: swap;
	unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
	font-style: normal;
	font-weight: 400;
	src: url(https://fonts.gstatic.com/s/blackopsone/v20/qWcsB6-ypo7xBdr6Xshe96H3aDXbtxsis4IxXw.woff2) format('woff2');
	font-family: 'Black Ops One';
	font-display: swap;
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
	font-style: normal;
	font-weight: 400;
	src: url(https://fonts.gstatic.com/s/blackopsone/v20/qWcsB6-ypo7xBdr6Xshe96H3aDvbtxsis4I.woff2) format('woff2');
	font-family: 'Black Ops One';
	font-display: swap;
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
		U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

// Inline from https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,slnt,wdth,wght,GRAD,XTRA,YOPQ,YTAS,YTDE,YTFI,YTLC,YTUC@8..144,0,130,300,0,468,79,750,-203,738,514,712&display=swap
/* cyrillic-ext */
@font-face {
	font-style: normal;
	font-weight: 300;
	font-stretch: 130%;
	src: url(https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wCikXpYqmZsWI-__OGfttPZktqc2VdZ80KvCLZaPcSBZtOx2MifRuWR28sPJtUMbsFEK6cRrleUx9Xgbm3WLHa_F4Ep4Fm0PN19Ik5Dntczx0wZGzhPlL1YNMYKbv9_1IQXOw7AiUJVXRrV8cWW4O8LJCCXxhKEbQ.woff2)
		format('woff2');
	font-family: 'Roboto Flex';
	font-display: swap;
	unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
	font-style: normal;
	font-weight: 300;
	font-stretch: 130%;
	src: url(https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wCikXpYqmZsWI-__OGfttPZktqc2VdZ80KvCLZaPcSBZtOx2MifRuWR28sPJtUMbsFEK6cRrleUx9Xgbm3WLHa_F4Ep4Fm0PN19Ik5Dntczx0wZGzhPlL1YNMYKbv9_1IQXOw7AiUJVXRrV8cWW4O8LJCCVhhKEbQ.woff2)
		format('woff2');
	font-family: 'Roboto Flex';
	font-display: swap;
	unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek */
@font-face {
	font-style: normal;
	font-weight: 300;
	font-stretch: 130%;
	src: url(https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wCikXpYqmZsWI-__OGfttPZktqc2VdZ80KvCLZaPcSBZtOx2MifRuWR28sPJtUMbsFEK6cRrleUx9Xgbm3WLHa_F4Ep4Fm0PN19Ik5Dntczx0wZGzhPlL1YNMYKbv9_1IQXOw7AiUJVXRrV8cWW4O8LJCCURhKEbQ.woff2)
		format('woff2');
	font-family: 'Roboto Flex';
	font-display: swap;
	unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
	font-style: normal;
	font-weight: 300;
	font-stretch: 130%;
	src: url(https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wCikXpYqmZsWI-__OGfttPZktqc2VdZ80KvCLZaPcSBZtOx2MifRuWR28sPJtUMbsFEK6cRrleUx9Xgbm3WLHa_F4Ep4Fm0PN19Ik5Dntczx0wZGzhPlL1YNMYKbv9_1IQXOw7AiUJVXRrV8cWW4O8LJCCXRhKEbQ.woff2)
		format('woff2');
	font-family: 'Roboto Flex';
	font-display: swap;
	unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
	font-style: normal;
	font-weight: 300;
	font-stretch: 130%;
	src: url(https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wCikXpYqmZsWI-__OGfttPZktqc2VdZ80KvCLZaPcSBZtOx2MifRuWR28sPJtUMbsFEK6cRrleUx9Xgbm3WLHa_F4Ep4Fm0PN19Ik5Dntczx0wZGzhPlL1YNMYKbv9_1IQXOw7AiUJVXRrV8cWW4O8LJCCXBhKEbQ.woff2)
		format('woff2');
	font-family: 'Roboto Flex';
	font-display: swap;
	unicode-range: U+0100-02AF, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
	font-style: normal;
	font-weight: 300;
	font-stretch: 130%;
	src: url(https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wCikXpYqmZsWI-__OGfttPZktqc2VdZ80KvCLZaPcSBZtOx2MifRuWR28sPJtUMbsFEK6cRrleUx9Xgbm3WLHa_F4Ep4Fm0PN19Ik5Dntczx0wZGzhPlL1YNMYKbv9_1IQXOw7AiUJVXRrV8cWW4O8LJCCUhhK.woff2)
		format('woff2');
	font-family: 'Roboto Flex';
	font-display: swap;
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
		U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

// Inline from https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,slnt,wdth,wght,GRAD,XTRA,YOPQ,YTAS,YTDE,YTFI,YTLC,YTUC@8..144,0,130,400,0,468,79,750,-203,738,514,712&display=swap
/* cyrillic-ext */
@font-face {
	font-style: normal;
	font-weight: 400;
	font-stretch: 130%;
	src: url(https://fonts.gstatic.com/s/robotoflex/v9/NaNeepOXO_NexZs0b5QrzlOHb8wCikXpYqmZsWI-__OGfttPZktqc2VdZ80KvCLZaPcSBZtOx2MifRuWR28sPJtUMbsFEK6cRrleUx9Xgbm3WLHa_F4Ep4Fm0PN19Ik5Dntczx0wZGzhPlL1YNMYKbv9_1IQXOw7AiUJVXRrV8cWW4O8LJCoXjC7-ShSaLshNP1c9-wmF9lp.woff2)
		format('woff2');
	font-family: 'Roboto Flex';
	font-display: swap;
	unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
	font-style: normal;
	font-weight: 400;
	font-stretch: 130%;
	src: url(https://fonts.gstatic.com/s/robotoflex/v9/NaNeepOXO_NexZs0b5QrzlOHb8wCikXpYqmZsWI-__OGfttPZktqc2VdZ80KvCLZaPcSBZtOx2MifRuWR28sPJtUMbsFEK6cRrleUx9Xgbm3WLHa_F4Ep4Fm0PN19Ik5Dntczx0wZGzhPlL1YNMYKbv9_1IQXOw7AiUJVXRrV8cWW4O8LJCoXjC7-ShSaLshNP1c9-UmF9lp.woff2)
		format('woff2');
	font-family: 'Roboto Flex';
	font-display: swap;
	unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek */
@font-face {
	font-style: normal;
	font-weight: 400;
	font-stretch: 130%;
	src: url(https://fonts.gstatic.com/s/robotoflex/v9/NaNeepOXO_NexZs0b5QrzlOHb8wCikXpYqmZsWI-__OGfttPZktqc2VdZ80KvCLZaPcSBZtOx2MifRuWR28sPJtUMbsFEK6cRrleUx9Xgbm3WLHa_F4Ep4Fm0PN19Ik5Dntczx0wZGzhPlL1YNMYKbv9_1IQXOw7AiUJVXRrV8cWW4O8LJCoXjC7-ShSaLshNP1c9-ImF9lp.woff2)
		format('woff2');
	font-family: 'Roboto Flex';
	font-display: swap;
	unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
	font-style: normal;
	font-weight: 400;
	font-stretch: 130%;
	src: url(https://fonts.gstatic.com/s/robotoflex/v9/NaNeepOXO_NexZs0b5QrzlOHb8wCikXpYqmZsWI-__OGfttPZktqc2VdZ80KvCLZaPcSBZtOx2MifRuWR28sPJtUMbsFEK6cRrleUx9Xgbm3WLHa_F4Ep4Fm0PN19Ik5Dntczx0wZGzhPlL1YNMYKbv9_1IQXOw7AiUJVXRrV8cWW4O8LJCoXjC7-ShSaLshNP1c9-4mF9lp.woff2)
		format('woff2');
	font-family: 'Roboto Flex';
	font-display: swap;
	unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
	font-style: normal;
	font-weight: 400;
	font-stretch: 130%;
	src: url(https://fonts.gstatic.com/s/robotoflex/v9/NaNeepOXO_NexZs0b5QrzlOHb8wCikXpYqmZsWI-__OGfttPZktqc2VdZ80KvCLZaPcSBZtOx2MifRuWR28sPJtUMbsFEK6cRrleUx9Xgbm3WLHa_F4Ep4Fm0PN19Ik5Dntczx0wZGzhPlL1YNMYKbv9_1IQXOw7AiUJVXRrV8cWW4O8LJCoXjC7-ShSaLshNP1c9-8mF9lp.woff2)
		format('woff2');
	font-family: 'Roboto Flex';
	font-display: swap;
	unicode-range: U+0100-02AF, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
	font-style: normal;
	font-weight: 400;
	font-stretch: 130%;
	src: url(https://fonts.gstatic.com/s/robotoflex/v9/NaNeepOXO_NexZs0b5QrzlOHb8wCikXpYqmZsWI-__OGfttPZktqc2VdZ80KvCLZaPcSBZtOx2MifRuWR28sPJtUMbsFEK6cRrleUx9Xgbm3WLHa_F4Ep4Fm0PN19Ik5Dntczx0wZGzhPlL1YNMYKbv9_1IQXOw7AiUJVXRrV8cWW4O8LJCoXjC7-ShSaLshNP1c9-EmFw.woff2)
		format('woff2');
	font-family: 'Roboto Flex';
	font-display: swap;
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
		U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

// Inline from https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,slnt,wdth,wght,GRAD,XTRA,YOPQ,YTAS,YTDE,YTFI,YTLC,YTUC@8..144,0,130,600,0,468,79,750,-203,738,514,712&display=swap
/* cyrillic-ext */
@font-face {
	font-style: normal;
	font-weight: 600;
	font-stretch: 130%;
	src: url(https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wCikXpYqmZsWI-__OGfttPZktqc2VdZ80KvCLZaPcSBZtOx2MifRuWR28sPJtUMbsFEK6cRrleUx9Xgbm3WLHa_F4Ep4Fm0PN19Ik5Dntczx0wZGzhPlL1YNMYKbv9_1IQXOw7AiUJVXRrV8cWW4O8LJCCXxhKEbQ.woff2)
		format('woff2');
	font-family: 'Roboto Flex';
	font-display: swap;
	unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
	font-style: normal;
	font-weight: 600;
	font-stretch: 130%;
	src: url(https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wCikXpYqmZsWI-__OGfttPZktqc2VdZ80KvCLZaPcSBZtOx2MifRuWR28sPJtUMbsFEK6cRrleUx9Xgbm3WLHa_F4Ep4Fm0PN19Ik5Dntczx0wZGzhPlL1YNMYKbv9_1IQXOw7AiUJVXRrV8cWW4O8LJCCVhhKEbQ.woff2)
		format('woff2');
	font-family: 'Roboto Flex';
	font-display: swap;
	unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek */
@font-face {
	font-style: normal;
	font-weight: 600;
	font-stretch: 130%;
	src: url(https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wCikXpYqmZsWI-__OGfttPZktqc2VdZ80KvCLZaPcSBZtOx2MifRuWR28sPJtUMbsFEK6cRrleUx9Xgbm3WLHa_F4Ep4Fm0PN19Ik5Dntczx0wZGzhPlL1YNMYKbv9_1IQXOw7AiUJVXRrV8cWW4O8LJCCURhKEbQ.woff2)
		format('woff2');
	font-family: 'Roboto Flex';
	font-display: swap;
	unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
	font-style: normal;
	font-weight: 600;
	font-stretch: 130%;
	src: url(https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wCikXpYqmZsWI-__OGfttPZktqc2VdZ80KvCLZaPcSBZtOx2MifRuWR28sPJtUMbsFEK6cRrleUx9Xgbm3WLHa_F4Ep4Fm0PN19Ik5Dntczx0wZGzhPlL1YNMYKbv9_1IQXOw7AiUJVXRrV8cWW4O8LJCCXRhKEbQ.woff2)
		format('woff2');
	font-family: 'Roboto Flex';
	font-display: swap;
	unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
	font-style: normal;
	font-weight: 600;
	font-stretch: 130%;
	src: url(https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wCikXpYqmZsWI-__OGfttPZktqc2VdZ80KvCLZaPcSBZtOx2MifRuWR28sPJtUMbsFEK6cRrleUx9Xgbm3WLHa_F4Ep4Fm0PN19Ik5Dntczx0wZGzhPlL1YNMYKbv9_1IQXOw7AiUJVXRrV8cWW4O8LJCCXBhKEbQ.woff2)
		format('woff2');
	font-family: 'Roboto Flex';
	font-display: swap;
	unicode-range: U+0100-02AF, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
	font-style: normal;
	font-weight: 600;
	font-stretch: 130%;
	src: url(https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wCikXpYqmZsWI-__OGfttPZktqc2VdZ80KvCLZaPcSBZtOx2MifRuWR28sPJtUMbsFEK6cRrleUx9Xgbm3WLHa_F4Ep4Fm0PN19Ik5Dntczx0wZGzhPlL1YNMYKbv9_1IQXOw7AiUJVXRrV8cWW4O8LJCCUhhK.woff2)
		format('woff2');
	font-family: 'Roboto Flex';
	font-display: swap;
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
		U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

// Inline from https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,slnt,wdth,wght,GRAD,XTRA,YOPQ,YTAS,YTDE,YTFI,YTLC,YTUC@8..144,0,130,700,0,468,79,750,-203,738,514,712&display=swap
/* cyrillic-ext */
@font-face {
	font-style: normal;
	font-weight: 700;
	font-stretch: 130%;
	src: url(https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wCikXpYqmZsWI-__OGfttPZktqc2VdZ80KvCLZaPcSBZtOx2MifRuWR28sPJtUMbsFEK6cRrleUx9Xgbm3WLHa_F4Ep4Fm0PN19Ik5Dntczx0wZGzhPlL1YNMYKbv9_1IQXOw7AiUJVXRrV8cWW4O8LJCCXxhKEbQ.woff2)
		format('woff2');
	font-family: 'Roboto Flex';
	font-display: swap;
	unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
	font-style: normal;
	font-weight: 700;
	font-stretch: 130%;
	src: url(https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wCikXpYqmZsWI-__OGfttPZktqc2VdZ80KvCLZaPcSBZtOx2MifRuWR28sPJtUMbsFEK6cRrleUx9Xgbm3WLHa_F4Ep4Fm0PN19Ik5Dntczx0wZGzhPlL1YNMYKbv9_1IQXOw7AiUJVXRrV8cWW4O8LJCCVhhKEbQ.woff2)
		format('woff2');
	font-family: 'Roboto Flex';
	font-display: swap;
	unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek */
@font-face {
	font-style: normal;
	font-weight: 700;
	font-stretch: 130%;
	src: url(https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wCikXpYqmZsWI-__OGfttPZktqc2VdZ80KvCLZaPcSBZtOx2MifRuWR28sPJtUMbsFEK6cRrleUx9Xgbm3WLHa_F4Ep4Fm0PN19Ik5Dntczx0wZGzhPlL1YNMYKbv9_1IQXOw7AiUJVXRrV8cWW4O8LJCCURhKEbQ.woff2)
		format('woff2');
	font-family: 'Roboto Flex';
	font-display: swap;
	unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
	font-style: normal;
	font-weight: 700;
	font-stretch: 130%;
	src: url(https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wCikXpYqmZsWI-__OGfttPZktqc2VdZ80KvCLZaPcSBZtOx2MifRuWR28sPJtUMbsFEK6cRrleUx9Xgbm3WLHa_F4Ep4Fm0PN19Ik5Dntczx0wZGzhPlL1YNMYKbv9_1IQXOw7AiUJVXRrV8cWW4O8LJCCXRhKEbQ.woff2)
		format('woff2');
	font-family: 'Roboto Flex';
	font-display: swap;
	unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
	font-style: normal;
	font-weight: 700;
	font-stretch: 130%;
	src: url(https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wCikXpYqmZsWI-__OGfttPZktqc2VdZ80KvCLZaPcSBZtOx2MifRuWR28sPJtUMbsFEK6cRrleUx9Xgbm3WLHa_F4Ep4Fm0PN19Ik5Dntczx0wZGzhPlL1YNMYKbv9_1IQXOw7AiUJVXRrV8cWW4O8LJCCXBhKEbQ.woff2)
		format('woff2');
	font-family: 'Roboto Flex';
	font-display: swap;
	unicode-range: U+0100-02AF, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
	font-style: normal;
	font-weight: 700;
	font-stretch: 130%;
	src: url(https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wCikXpYqmZsWI-__OGfttPZktqc2VdZ80KvCLZaPcSBZtOx2MifRuWR28sPJtUMbsFEK6cRrleUx9Xgbm3WLHa_F4Ep4Fm0PN19Ik5Dntczx0wZGzhPlL1YNMYKbv9_1IQXOw7AiUJVXRrV8cWW4O8LJCCUhhK.woff2)
		format('woff2');
	font-family: 'Roboto Flex';
	font-display: swap;
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
		U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

:root {
	--header-background-color: black;
	--header-foreground-color: var(--color-white);
}
:root {
	--app-height: 100vh;
	--app-width: 100vw;
}
* {
	position: relative;
	box-sizing: border-box;
}
html {
	display: flex;
	flex-direction: column;
}
html {
	margin: 0;
	padding: 0;
	max-width: 100%;
	min-height: 100%;
	font-size: 16px;
}
body {
	margin: 0;
	background: black;
	padding: 0;
	width: 100%;
	max-width: 100%;
	font: var(--font-body-m-r);
}
p,
li {
	line-height: 1.7;
}
input {
	font-family: var(--font-main);
}
body,
#root {
	display: grid;
	grid-template-rows: 1fr;
	grid-template-columns: [full-start] 1fr [full-end];
	flex: 1;
	/* background-color: ${styles.BRAND_PRIMARY}; */
}
.content {
	display: grid;
	grid-template-rows: 1fr;
	grid-template-columns: [full-start content-start] 1fr [content-end full-end];
	grid-column: full;
}
@media (min-width: 760px) {
	.content {
		grid-template-columns:
			[full-start] 1fr [content-start] min(calc(var(--app-width) - var(--size-gap-2x) * 2), var(--size-content-width))
			[content-end] 1fr [full-end];
	}
}
h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
}
h1 {
	color: var(--color-brand-primary);
	font: var(--font-display-l);
}
h2 {
	color: var(--color-brand-primary);
	font: var(--font-display-m);
}
h3 {
	font: var(--font-display-s);
}
img {
	display: inline-block;
}
button {
	cursor: pointer;
	outline: none;
	box-shadow: none;
	border: 0;
	font-size: inherit;
}
[role='button']:hover {
	cursor: pointer;
}
b {
	font-weight: 600;
}

:root {
	--gap: min(8px, 5vw);
	--row-gap: 0.55rem;
}
@include mixins.desktop {
	:root {
		--gap: 8px;
	}
}

@media (max-width: root.$size-desktop-width) {
	.desktop-only {
		display: none !important;
	}
}

@media (min-width: root.$size-desktop-width) {
	.mobile-only {
		display: none !important;
	}
}

a {
	color: var(--color-brand-primary);
	text-decoration: none;
	&:hover {
		text-decoration: underline;
	}
}
a.inherit {
	color: inherit;
	text-decoration: none;
	&:hover {
		text-decoration: underline;
	}
}

.padded {
	padding: var(--size-gap-2x);
}
