.Button {
	display: flex;
	justify-content: center;
	align-items: center;
	border: 1px solid var(--color-brand-primary);
	border-radius: 1000px;
	background-color: var(--color-brand-primary);
	padding: var(--size-gap-2x);
	color: var(--color-black);
	font: var(--font-button);
	text-align: center;
	text-transform: uppercase;

	&.Medium {
		padding: 0px 40px;
		height: 40px;
	}

	&.Small {
		padding: 0px 32px;
		height: 40px;
	}

	&.Inverted {
		border: 1px solid var(--color-brand-primary);
		background: black;
		color: var(--color-brand-primary);
	}

	&:hover {
		text-decoration: none;
		&:after {
			opacity: 1;
		}
	}

	&:after {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		opacity: 0;
		// mix-blend-mode: overlay;
		transition: 0.2s ease all;
		border-radius: inherit;
		// background-image: linear-gradient(120deg, transparent, rgba(0, 0, 0, 0.5));
		background-image: linear-gradient(140deg, var(--color-brand-primary), var(--color-brand-primary-10));
		content: '';
	}

	&.Inverted {
		&:after {
			background-image: linear-gradient(140deg, black, var(--color-brand-primary-dark));
		}
	}

	> div {
		z-index: 1;
	}
}
