.Tabs {
	position: relative;

	.TabsLinks {
		display: flex;
		top: var(--size-gap-2x);
		margin-bottom: calc(-1 * var(--size-gap-2x));
		padding: 0;
		padding-right: var(--size-gap-4x);
		padding-bottom: var(--size-gap-2x);
		padding-left: var(--size-gap-half);
		max-width: var(--app-width);
		overflow-x: auto;
		font: var(--font-headline-xs);
		> a {
			display: block;
			border-bottom: 1px solid transparent;
			padding: var(--size-gap-2x) var(--size-gap) var(--size-gap);
			white-space: nowrap;

			&:hover {
				text-decoration: none;
			}

			&.current {
				color: var(--color-brand-primary-20);
			}
		}
	}
}

.Tabs::before {
	position: absolute;
	bottom: 4px;
	left: 0;
	z-index: 1;
	background: linear-gradient(90deg, var(--color-black), transparent);
	width: var(--size-gap-2x);
	height: 100%;
	pointer-events: none;
	content: '';
}
.Tabs::after {
	position: absolute;
	right: 0;
	bottom: 4px;
	background: linear-gradient(90deg, transparent, var(--color-black) var(--size-gap-4x));
	width: var(--size-gap-6x);
	height: 100%;
	pointer-events: none;
	content: '';
}
