@use '~/src/lib/mixins';
.Header {
	position: sticky;
	top: 0;
	z-index: 100;
	border-bottom: 1px solid var(--color-offblack);
	color: var(--header-foreground-color);

	.Background {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 0;
		background-color: var(--header-background-color);
		width: 100%;
		height: 100%;
	}
	.HeaderContainer {
		display: grid;
		grid-template-columns: auto 1fr auto auto;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		gap: var(--size-gap-2x);
		padding: var(--size-gap-quarter) var(--size-gap-2x);
		text-transform: uppercase;
		@include mixins.desktop {
			padding: var(--size-gap-quarter);
		}

		.Logo {
			padding: var(--size-gap-2x) 0;
			@include mixins.desktop {
				margin-right: var(--size-gap-2x);
			}
			img {
				display: block;
				width: 60px;
				height: 23px;
			}
		}

		.LinksContainer {
			display: flex;
			top: 2px;
			flex-direction: row;
			justify-content: flex-start;
			align-items: center;
			gap: var(--size-gap-2x);
			padding: var(--size-gap-2x);
			padding-right: 0;
			font: var(--font-button);
			text-transform: uppercase;
			white-space: nowrap;
			@include mixins.desktop {
				gap: var(--size-gap-2x);
			}
			a {
				top: 0;
				color: var(--color-gray);
				font: var(--font-button);
			}
			.Tickets {
				margin-left: var(--size-gap-2x);
				color: var(--color-brand-primary);
				font: var(--font-eyebrow);
				svg {
					margin-right: var(--size-gap);
				}
			}
			.Shop {
				margin-left: var(--size-gap-2x);
				color: var(--color-brand-primary);
				font: var(--font-eyebrow);
				svg {
					margin-right: var(--size-gap);
				}
			}
			.DesktopLinks {
				display: none;
				@include mixins.desktop {
					display: inherit;
					gap: var(--size-gap-4x);
				}

				a:after {
					display: block;
					position: absolute;
					top: 0;
					right: calc(-1 * var(--size-gap-2x) - 1px);
					background-color: var(--color-brand-primary);
					width: 1px;
					height: 100%;
					content: '';
				}
				a:last-of-type:after {
					display: none;
				}
			}
		}
	}
	.MenuIcon {
		color: var(--color-brand-primary);
	}

	nav {
		a {
			margin: 0 var(--size-gap-3x);
			border-bottom: 1px solid var(--color-brand-primary);
			padding: var(--size-gap-3x) 0;
			color: var(--color-gray);
			text-transform: uppercase;
		}
	}
}
