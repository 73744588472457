@use '~/src/lib/mixins';

.Table {
	table {
		border-width: 0;
		border-spacing: 0;
		width: 100%;
		table-layout: auto;
	}

	thead tr th {
		background-color: black;
		padding: var(--size-gap-2x);
		color: var(--color-white);
		font: var(--font-body-s-sb);
		text-align: center;
		white-space: nowrap;

		&:first-child {
			position: sticky;
			left: 0;
			z-index: 1;
			padding: var(--size-gap-2x);
			min-width: 160px;
			text-align: left;

			@include mixins.desktop {
				min-width: 160px;
			}
		}

		&:nth-child(n + 2) {
			min-width: 60px;
		}

		&.current {
			background-color: var(--color-brand-secondary);
		}
	}

	tbody {
		th {
			position: sticky;
			left: 0;
			z-index: 1;
			background-color: var(--color-offblack);
			padding: var(--size-gap-2x);
			text-align: left;
		}

		th,
		td {
			color: var(--color-white);
			font: var(--font-body-s-sb);
		}

		td {
			background-color: var(--color-black);
			min-width: 40px;
			text-align: center;
		}
	}
}
