//This file is automatically generated by the build process
//Edit styles.json then run `npm run build` to update
$color-brand-primary: #c3fa02;
$color-brand-primary-10: #f6ffd9;
$color-brand-primary-20: #ecfeae;
$color-brand-primary-30: #dcfd67;
$color-brand-secondary: #46928f;
$color-brand-link: #46928f;
$color-brand-primary-dark: #3e5001;
$color-offblack: #333;
$color-black: #1a1a1a;
$color-white: #ffffff;
$color-gray: #878787;
$color-darkgray: #1c1e21;
$color-lightgray: #cccccc;
$color-offwhite: #fafafa;
$color-shadow: rgba(0,0,0,0.25);
$color-inner-shadow: rgba(0,0,0,0.05);
$color-amazon-blue: #1a98ff;
$color-youtube-red: #ff0000;
$font-display-xl: 64px/1.2 'black ops one', impact;
$font-display-l: 56px/1.2 'black ops one', impact;
$font-display-m: 40px/1.2 'black ops one', impact;
$font-display-s: 24px/1.2 'black ops one', impact;
$font-display-xs: 18px/1.2 'black ops one', impact;
$font-headline-l: 300 40px/1.2 'roboto flex', arial;
$font-headline-m: 300 32px/1.3 'roboto flex', arial;
$font-headline-s: 300 24px/1.3 'roboto flex', arial;
$font-headline-xs: 300 16px/1.3 'roboto flex', arial;
$font-title-l: 600 24px/1.33 'roboto flex', arial;
$font-title-s: 600 18px/1.33 'roboto flex', arial;
$font-body-l-sb: 600 24px/1.5 'roboto flex', arial;
$font-body-l-r: 400 24px/1.5 'roboto flex', arial;
$font-body-m-sb: 600 18px/1.5 'roboto flex', arial;
$font-body-m-r: 400 18px/1.5 'roboto flex', arial;
$font-body-s-sb: 600 16px/1.5 'roboto flex', arial;
$font-body-s-r: 400 16px/1.5 'roboto flex', arial;
$font-body-xs-b: 700 12px/1.5 'roboto flex', arial;
$font-body-xs-r: 400 12px/1.5 'roboto flex', arial;
$font-number-l: 24px/1.33 'black ops one', impact;
$font-number-s: 18px/1.33 'black ops one', impact;
$font-eyebrow: 12px/1.33 'black ops one', impact;
$font-button: 600 14px/16px 'roboto flex', arial;
$size-gap: var(--gap);
$size-gap-quarter: calc(var(--gap) / 4);
$size-gap-half: calc(var(--gap) / 2);
$size-gap-2x: calc(var(--gap) * 2);
$size-gap-3x: calc(var(--gap) * 3);
$size-gap-4x: calc(var(--gap) * 4);
$size-gap-6x: calc(var(--gap) * 6);
$size-row-gap: var(--row-gap);
$size-large-mobile-width: 640px;
$size-tablet-width: 768px;
$size-desktop-width: 990px;
$size-content-width: calc(1366px - var(--size-gap) * 2);
:root {
--color-brand-primary: #c3fa02;
--color-brand-primary-10: #f6ffd9;
--color-brand-primary-20: #ecfeae;
--color-brand-primary-30: #dcfd67;
--color-brand-secondary: #46928f;
--color-brand-link: #46928f;
--color-brand-primary-dark: #3e5001;
--color-offblack: #333;
--color-black: #1a1a1a;
--color-white: #ffffff;
--color-gray: #878787;
--color-darkgray: #1c1e21;
--color-lightgray: #cccccc;
--color-offwhite: #fafafa;
--color-shadow: rgba(0,0,0,0.25);
--color-inner-shadow: rgba(0,0,0,0.05);
--color-amazon-blue: #1a98ff;
--color-youtube-red: #ff0000;
--font-display-xl: 64px/1.2 'black ops one', impact;
--font-display-l: 56px/1.2 'black ops one', impact;
--font-display-m: 40px/1.2 'black ops one', impact;
--font-display-s: 24px/1.2 'black ops one', impact;
--font-display-xs: 18px/1.2 'black ops one', impact;
--font-headline-l: 300 40px/1.2 'roboto flex', arial;
--font-headline-m: 300 32px/1.3 'roboto flex', arial;
--font-headline-s: 300 24px/1.3 'roboto flex', arial;
--font-headline-xs: 300 16px/1.3 'roboto flex', arial;
--font-title-l: 600 24px/1.33 'roboto flex', arial;
--font-title-s: 600 18px/1.33 'roboto flex', arial;
--font-body-l-sb: 600 24px/1.5 'roboto flex', arial;
--font-body-l-r: 400 24px/1.5 'roboto flex', arial;
--font-body-m-sb: 600 18px/1.5 'roboto flex', arial;
--font-body-m-r: 400 18px/1.5 'roboto flex', arial;
--font-body-s-sb: 600 16px/1.5 'roboto flex', arial;
--font-body-s-r: 400 16px/1.5 'roboto flex', arial;
--font-body-xs-b: 700 12px/1.5 'roboto flex', arial;
--font-body-xs-r: 400 12px/1.5 'roboto flex', arial;
--font-number-l: 24px/1.33 'black ops one', impact;
--font-number-s: 18px/1.33 'black ops one', impact;
--font-eyebrow: 12px/1.33 'black ops one', impact;
--font-button: 600 14px/16px 'roboto flex', arial;
--size-gap: var(--gap);
--size-gap-quarter: calc(var(--gap) / 4);
--size-gap-half: calc(var(--gap) / 2);
--size-gap-2x: calc(var(--gap) * 2);
--size-gap-3x: calc(var(--gap) * 3);
--size-gap-4x: calc(var(--gap) * 4);
--size-gap-6x: calc(var(--gap) * 6);
--size-row-gap: var(--row-gap);
--size-large-mobile-width: 640px;
--size-tablet-width: 768px;
--size-desktop-width: 990px;
--size-content-width: calc(1366px - var(--size-gap) * 2);
}
