@use '~/src/lib/mixins';

.IndexGames {
	@include mixins.bordered;
	display: grid;
	gap: var(--size-gap-2x);
	margin: 0 16px;
	padding: var(--size-gap-2x);
	width: 100%;
	color: var(--color-white);

	@include mixins.desktop {
		margin: 0;

		max-width: 810px;
	}

	h2 {
		color: var(--color-white);
		font: var(--font-headline-s);
	}

	.Button {
		align-self: center;
		width: 100%;
		max-width: 240px;
	}

	.More {
		&:before {
			display: block;
			position: absolute;
			top: calc(-1 * var(--size-gap-2x));
			left: 0;
			background-color: var(--color-brand-primary);
			width: 100%;
			height: 1px;
			content: '';
		}
	}

	.Games {
		display: grid;
		grid-template-columns: 1fr;
		row-gap: var(--size-gap-2x);

		@include mixins.desktop {
			column-gap: var(--size-gap-2x);

			&.MultiColumn {
				grid-template-columns: 1fr 1fr 1fr;

				.Game:nth-last-child(-n + 3) {
					border-bottom-color: transparent;
				}
			}
		}
	}
}
