.TeamsLogo {
	border-radius: 50%;
	width: 56px;
	text-decoration: none !important;

	img,
	.Placeholder {
		display: block;
		border-radius: 50%;
		aspect-ratio: 1;
		width: 100%;
	}

	.Placeholder {
		display: flex;
		flex-direction: column;
		justify-content: center;
		background-color: var(--color-white);
		padding-top: 2px;
		width: 100%;
		color: var(--color-black);
		font: var(--font-display-m);
		text-align: center;
	}
}
