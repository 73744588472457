@use '~/src/lib/mixins';

.NotFound {
	@include mixins.center-column;

	margin-bottom: var(--size-gap-3x);
	color: var(--color-white);

	> h1 {
		margin: var(--size-gap-2x);
		font: var(--font-display-l);
	}
	h2 {
		font: var(--font-display-m);
	}
}
