.IndexGame {
	display: flex;
	flex-direction: column;
	gap: var(--size-gap);
	border-bottom: 1px solid var(--color-brand-primary-30);
	padding-bottom: var(--size-gap-2x);
	&.Game:nth-last-child(1) {
		border-bottom-color: transparent;
	}

	&.Scheduled {
		.Points {
			display: none;
			color: var(--color-gray) !important;
		}
	}
	.Purpose {
		background-color: var(--color-brand-primary-20);
		padding: var(--size-gap-half);
		color: var(--color-black);
		font: var(--font-eyebrow);
		text-align: center;
		text-transform: uppercase;
	}
	.GameTeam {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		&:first-of-type {
			border-bottom: 1px solid var(--color-offblack);
			padding-bottom: var(--size-gap);
		}
		.LogoName {
			display: flex;
			flex-direction: row;
			align-items: center;
			gap: var(--size-gap);
		}
		.Logo {
			border: 1px solid var(--color-gray);
			border-radius: 1000px;
			background-color: var(--color-white);
			aspect-ratio: 1;
			width: 32px;
			&.Placeholder {
				padding: 6px;
			}
		}
		.Name {
			font: var(--font-body-s-sb);
			&.Loser {
				color: var(--color-gray);
			}
		}
		.TBD {
			margin: 0 0 calc(-1 * var(--size-gap-half));
			height: 32px;
			line-height: 32px;
			font: var(--font-display-xs);
		}
		.Points {
			color: var(--color-brand-primary-20);
			font: var(--font-number-l);
			&.Leader {
				color: var(--color-brand-primary);
			}
			&.Loser {
				color: var(--color-gray);
			}
		}
	}
}
