@use '~/src/lib/_root';

@mixin center-column {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

@mixin max-lines($lines) {
	-webkit-line-clamp: $lines;
	-webkit-box-orient: vertical;
	display: -webkit-box;
	overflow: hidden;
	text-overflow: ellipsis;
}

@mixin clip-corners($radius: 14px) {
	clip-path: polygon($radius 0, 100% 0, 100% calc(100% - $radius), calc(100% - $radius) 100%, 0 100%, 0 $radius);
}

@mixin bordered {
	border: 1px solid var(--color-brand-primary);
	@include rounded();
}

@mixin rounded {
	border-radius: 16px;
	overflow: hidden;
}

@mixin mobile-padded {
	padding: var(--size-gap-2x);
	@include desktop {
		padding: 0;
	}
}

@mixin wide-desktop {
	@media (min-width: 1400px) {
		@content;
	}
}

@mixin desktop {
	@media (min-width: 990px) {
		@content;
	}
}

@mixin tablet {
	@media (min-width: 768px) {
		@content;
	}
}

@mixin small-mobile {
	@media (max-width: 420px) {
		@content;
	}
}
