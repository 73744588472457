.TBD {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: var(--size-gap-2x) 0;
	color: var(--color-brand-primary-10);
	font: var(--font-display-l);
	text-align: center;
}
